import React from 'react';

import { root } from './address.module.css';

const AddressWithProps = ({ phone, email, street, street2nd, zip, city, country }) => {
  return (
    <address>
      {street && (
        <p>
          {street}
          <br></br>
          {street2nd && (
            <>
              {street2nd}
              <br />
            </>
          )}
          {zip} {city}, {country}
        </p>
      )}
      {email && (
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      )}
      {phone && (
        <p>
          <a href={`mailto:${phone}`}>{phone}</a>
        </p>
      )}
    </address>
  );
};

const Address = ({ children }) => <address className={root}>{children}</address>;

export default Address;
