import React from 'react';

import { cleanPhoneNumber } from '../lib/string-utils';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';

import * as styles from './person-item.module.css';

const PersonItem = ({ name, title, image, email, phone, showEmail = false, ...rest }) => {
  return (
    <div className={styles.root} {...rest}>
      {image && (
        <img
          className={styles.image}
          key={image.alt}
          src={imageUrlFor(buildImageObj(image))
            .width(200)
            .url()}
          alt={image.alt || `${name} profiilikuva`}
        />
      )}

      <div className="margin-bottom-1">
        <p className="break-word margin-0">
          <b>{name}</b>
        </p>
        {title && <p className="margin-0">{title}</p>}
      </div>
      <div className="margin-bottom-1">
        {email && showEmail && (
          <p className="margin-0">
            <a className="break-word " href={`mailto:${email}`}>
              {email}
            </a>
          </p>
        )}
        {phone && (
          <p className="margin-0">
            <a className="break-word " href={`tel:${cleanPhoneNumber(phone)}`}>
              {phone}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default PersonItem;
