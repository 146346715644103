import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Layout from '../containers/layout';

import SEO from '../components/seo';
import Container from '../components/container';
import Address from '../components/address';
import PersonItem from '../components/person-item';
import { cleanPhoneNumber } from '../lib/string-utils';
import Map from '../components/map';

const query = graphql`
  query ContactPageQuery {
    company: sanityCompanyInformation(_id: { regex: "/(drafts.|)companyInformation/" }) {
      name
      address {
        street
        street2nd
        city
        country
      }
      phone
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      carouselImages {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      contactPeople {
        name
        title
        email
        phone
        image {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const ContactPage = () => {

  const data = useStaticQuery(query);

  const site = (data || {}).site;
  const company = (data || {}).company;

  if (!site || !company) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  company.email = 'ark@heikkinen-komonen.fi';

  const spacing = 8;
  return (
    <Layout logoVersion="B">
      <SEO title={'Yhteystiedot'} description={site.description} keywords={site.keywords} />

      <Container>
        <Grid container spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <h1 className={'title-1 uppercase'}>Yhteystiedot</h1>
            <Address>
              <p>
                <b>Arkkitehtuuritoimisto Heikkinen-Komonen Oy</b>
                <br />
                Tammasaarenlaituri 3
                <br />
                00180 Helsinki, Finland
                <br />
                <a
                  target="_blank"
                  title="Toimiston sijainti Google Maps -palvelussa"
                  rel="noopener noreferrer nofollow"
                  href="https://goo.gl/maps/Fs6dYjdeAkYxMJHK7"
                >
                  Näytä kartalla
                </a>
              </p>

              <div>
                {/*company.phone && (
                  <p className={'margin-0'}>
                    <a
                      className={'bare-link'}
                      href={`tel:${cleanPhoneNumber(company.phone)}`}
                      title={`Soita numberoon ${company.phone}`}
                    >
                      {company.phone}
                    </a>
                  </p>
                )*/}
                <p className={'margin-0'}>
                  <a
                    href="tel:+358407310530"
                    className={'bare-link'}
                    title="Soita +358 40 7310 530"
                  >
                    +358 40 7310 530
                  </a>
                </p>

                <p className={'margin-0'}>etunimi.sukunimi@heikkinen-komonen.fi</p>
                {/*company.email && (
                  <p className={'margin-0'}>
                    <a
                      className={'bare-link'}
                      href={`mailto:${company.email}`}
                      title={`Lähetä sähköpostia osoitteeseen ${company.email}`}
                    >
                      {company.email}
                    </a>
                  </p>
                )*/}
              </div>
            </Address>
            {/*<Map lng={24.934404307761543} lat={60.15817944987035} /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h2 className={'title-1 uppercase'}>Osakkaat</h2>
            <Grid container spacing={spacing}>
              {site.contactPeople &&
                site.contactPeople.map(person => (
                  <Grid item xs={12} md={6} component={PersonItem} key={person.name} {...person} />
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default ContactPage;
